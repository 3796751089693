import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { UserManager, WebStorageStateStore } from "oidc-client";
import oidcConfig from "../config/oidc-config";

const AuthContext = createContext({
  user: null,
  userManager: null,
  blueButtonToken: null,
  setBlueButtonToken: () => {},
  setPayerCallBackToken:()=>{},
  CoveragePlanCode: null,
  setCoveragePlanCode: () => {},
  PatientIDHL7: null,
  setPatientIDHL7: () => {},
  setUserRole: () => {},
  userRole: null,
  isLoading: false,
});

export const AuthProvider = ({ children }) => {
  const initialCoveragePlanCode = localStorage.getItem("CoveragePlanCode");
  const initialPatientIDHL7 = localStorage.getItem("PatientIDHL7");

  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(); // State for user role
  const [blueButtonToken, setBlueButtonToken] = useState();
  const[payerCallBackToken,setPayerCallBackToken] = useState();
  const [CoveragePlanCode, setCoveragePlanCodeState] = useState(
    initialCoveragePlanCode
  );
  const [PatientIDHL7, setPatientIDHL7State] = useState(initialPatientIDHL7);
  const [isLoading, setIsLoading] = useState(false);

  const userManager = useMemo(
    () =>
      new UserManager({
        ...oidcConfig,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
      }),
    []
  );

  const setCoveragePlanCode = (value) => {
    localStorage.setItem("CoveragePlanCode", value);
    setCoveragePlanCodeState(value);
  };

  const setPatientIDHL7 = (value) => {
    localStorage.setItem("PatientIDHL7", value);
    setPatientIDHL7State(value);
  };

  useEffect(() => {
    const initUser = async () => {
      try {
        const currentUser = await userManager.getUser();
        if (currentUser && !currentUser.expired) {
          setUser(currentUser);
          localStorage.removeItem("PortalAdmin-AccessToken");
          sessionStorage.removeItem("PortalAdmin-AccessToken");
          localStorage.setItem(
            "PortalAdmin-AccessToken",
            currentUser.access_token
          );
          sessionStorage.setItem(
            "PortalAdmin-AccessToken",
            currentUser.access_token
          );
        } else setUser(null);
      } catch (e) {
        console.error("Error loading user:", e);
      }
    };
    initUser();

  }, [userManager]);

  return (
    <AuthContext.Provider
      value={{
        user,
        userManager,
        blueButtonToken,
        payerCallBackToken,
        setBlueButtonToken,
        setPayerCallBackToken,
        CoveragePlanCode,
        setCoveragePlanCode,
        PatientIDHL7,
        setPatientIDHL7,
        userRole,
        setUserRole,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;