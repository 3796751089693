import React, { useEffect, useState } from "react";
import { Modal, Button, Toast, ToastContainer } from "react-bootstrap";
import "./PreviousInsurer.scss";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getDropDownListData,
  getPayerConfiguration,
  getPayerInformationbyID,
  updatePayerInformation,
} from "../../redux/slice/PrevInsuranceSlice";
import { redirectToPrevInsuranceEndpoint } from "../../config/prevInsuranceAuthconfig";
import { useLocation } from "react-router-dom";
import { Http } from "../../api/Http";
import { urls } from "../../api/Urls";
import { formatDateToDDMMYY } from "../../utils/Utils";
import SyncIocon from "../../assets/img/sync.gif";
import { Tooltip } from "react-tooltip";

const PreviousInsurer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFromPayerCallback] = useState(location.state?.isFromPayerCallback);
  const [ssoId, setUserSub] = useState("");
  const dropDownData = useSelector(
    (state) => state?.insuranceData?.dropdownListData
  );
  const [isSyncComplete, setIsSyncComplete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDenyConfirmation, setShowDenyConfirmation] = useState(false); // Confirmation modal state
  const [currentPage, setCurrentPage] = useState(1);
  const [toastVisible, setToastVisible] = useState(false);
  const [previousPayerName, setPreviousPayerName] = useState({
    value: null,
    otherValue: "",
  });

  const [pendingPayerUpdate, setPendingPayerUpdate] = useState(null);

  const [payerName, setPayerName] = useState("");

  const handleConfirmDeny = (payer) => {
    setPayerName(payer.payerName);
    setShowDenyConfirmation(true);
  };

  const handleDenyConfirmed = () => {
    if (pendingPayerUpdate) {
      updatePayerStatus(pendingPayerUpdate.id, false);
    }
    setShowDenyConfirmation(false); // Close modal
  };

  const handleCancelDeny = () => {
    setShowDenyConfirmation(false); // Close confirmation modal without denying
  };

  const [selectedPayerId, setSelectedPayerId] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [isAlreadyCheck, setIsAlreadyChecked] = useState(false);
  const [showErrorModel, setShowErrorModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [isTableDataSync, setIsTableDataSync] = useState(false);

  const pageSize = 10;
  const payers = dropDownData
    ? dropDownData.map((payer) => ({
        value: payer.payerID,
        label: payer.payerName,
      }))
    : [];

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleUnsubscribeModal = () => {
    setShowModal(false);
    if (selectedPayerId !== null) {
      setSelectedPayerId(null);
      setPreviousPayerName("");
    }
  };

  const handlePayerChange = (selectedOption) => {
    setSelectedPayerId(selectedOption?.value);
    localStorage.setItem("previousPayerName", selectedOption?.label);
    // Update the state to reflect the new structure
    if (selectedOption?.value === "Other") {
      setPreviousPayerName({ value: "Other", otherValue: "" });
    } else {
      setPreviousPayerName({ value: selectedOption?.value, otherValue: "" });
    }

    const payerExists = gridData.some(
      (item) => item.payerID === selectedOption?.value && item.isActive
    );
    if (payerExists) {
      setIsAlreadyChecked(true);
      setShowToast(true);
    }

    // Reset other payer name if the selected option is not "Other"
    if (selectedOption?.value !== "Other") {
      setPreviousPayerName((prev) => ({ ...prev, otherValue: "" })); // Resetting only the otherValue
    }
  };

  const handleConnectToPayer = async (payerId) => {
    if (payerId && !isAlreadyCheck) {
      sessionStorage.setItem("selected_payer", payerId);
      await dispatch(getPayerInformationbyID(payerId));
      await dispatch(getPayerConfiguration(payerId));
      handleShowModal();
    } else {
      setShowErrorModel(true);
      setShowToast(true);
    }
  };

  const headers = [
    "Patient Id",
    "Payer Name",
    "Connected Date",
    "Disconnect Date",
    "Access",
  ];

  useEffect(() => {
    dispatch(getDropDownListData());
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("PortalAdmin-AccessToken");
    if (token) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const payload = JSON.parse(jsonPayload);
        setUserSub(payload.sub); // Extract the 'sub' value from the payload
      } catch (error) {}
    }
  }, []);

  const fetchGridData = async (
    payerName,
    id,
    page,
    pageSize,
    sortColumn,
    sortOrder
  ) => {
    setIsLoading(true); // Set loading to true before the API call
    try {
      const response = await Http.get(
        urls.previousInsurance.getListData(
          payerName,
          id,
          page,
          pageSize,
          sortColumn,
          sortOrder
        )
      );
      if (response) {
        setGridData(response?.items);

        // Update isTableDataSync if there are unsynced records
        const hasUnsyncedData = response?.items.some(
          (record) => record.isDataSync === false && record.dataSyncError === ""
        );
        setIsTableDataSync(hasUnsyncedData);

        // Update isSyncComplete if there are synced records
        const hasSyncedData = response?.items.some(
          (record) => record.isDataSync === true && record.dataSyncError === ""
        );
        setIsSyncComplete(hasSyncedData);

        setTotalCount(response?.totalCount);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after the API call completes
    }
  };

  const handlePrevInsuranceRedirection = () => {
    redirectToPrevInsuranceEndpoint();
  };

  const updatePayerStatus = (id, isActive) => {
    let data = {
      id: id,
      isActive: isActive, // Ensure false when denying access
      deActivatedDate: new Date().toISOString(),
    };
    dispatch(updatePayerInformation(data))
      .then(() => {
        setIsAlreadyChecked(false);
        fetchGridData(search, ssoId, currentPage, pageSize, "ID");
      })
      .catch((error) => {
        console.error("Error updating payer status:", error);
      });
  };

  const handleClose = () => {
    setIsAlreadyChecked(!isAlreadyCheck);
    setShowErrorModel(!showErrorModel);
    setPreviousPayerName(null);
    setSelectedPayerId(null);
    setToastVisible(false);
  };
  const handleNextPage = async () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    await fetchGridData(search, ssoId, nextPage, pageSize, "ID");
  };
  const handlePrevPage = async () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      await fetchGridData(search, ssoId, prevPage, pageSize, "ID");
    }
  };
  const totalPages = Math.ceil(totalCount / pageSize);
  useEffect(() => {
    if (ssoId && search === "") {
      fetchGridData(search, ssoId, currentPage, pageSize, "ID");
    }
  }, [search, ssoId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTableDataSync) {
        fetchGridData(search, ssoId, currentPage, pageSize, "ID");
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isTableDataSync, search, ssoId, currentPage, pageSize]);

  useEffect(() => {
    if (isFromPayerCallback) {
      setShowToast(true);
    }
  }, [isFromPayerCallback]);

  return (
    <div className="main-content">
      <div className="content-header">
        <h1 className="page-header">Previous Insurer</h1>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="card-form form-content">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                <label htmlFor="previousPayer" className="d-flex form-label">
                  Previous Payer
                </label>
                <div className="form-field position-relative custom-filter-select h-48">
                  <Select
                    id="previousPayer"
                    className="h-48 cursor-pointer"
                    value={
                      payers?.find(
                        (payer) => payer?.value === previousPayerName?.value
                      ) || null
                    }
                    onChange={handlePayerChange}
                    options={payers}
                    placeholder="Select"
                    isSearchable
                    isClearable
                  />
                </div>
              </div>
              <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                <button
                  type="button"
                  className="btn btn-primary h-48"
                  onClick={() => handleConnectToPayer(selectedPayerId)}
                  disabled={!selectedPayerId}
                >
                  <span>Connect to previous payer</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="form-content mb-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-xl-4 col-xxl-3 mb-4 mb-sm-0">
                  <label htmlFor="SearchLab" className="d-flex form-label">
                    Search Previous Insurer
                  </label>

                  <div className="form-field position-relative">
                    <input
                      id="SearchLab"
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="field-icon position-absolute top-50 translate-middle-y">
                      <i className="fh_search"></i>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-sm-end justify-content-center col-12 col-sm-auto">
                  <button
                    type="button"
                    className="btn btn-primary h-48 me-3"
                    onClick={() => fetchGridData(search, ssoId, 1, 10, "ID")}
                  >
                    <span>Search</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-primary h-48  me-3"
                    onClick={() => setSearch("")}
                  >
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            </div>
            <PerfectScrollbar className="table-responsive custom-main-table insurer-table">
              <table className="table">
                <thead className="sticky-top z-1">
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading && !isTableDataSync ? (
                    // Show loading spinner when isLoading is true
                    <tr>
                      <td colSpan={headers.length} className="text-center my-4">
                        <div className="spinner-border" role="status"></div>
                      </td>
                    </tr>
                  ) : gridData && gridData.length === 0 ? (
                    // Show no data found message if gridData is empty
                    <tr>
                      <td colSpan={headers.length} className="text-center">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    // Render the table rows if there is data
                    gridData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.payerMemberID ? item.payerMemberID : "-"}</td>
                        <td>{item.payerName}</td>
                        <td>{formatDateToDDMMYY(item.activeDate)}</td>
                        <td>
                          {
                            item.isActive
                              ? "-" // Hide Disconnect Date when Denied Access is ON
                              : item.deActivatedDate
                              ? formatDateToDDMMYY(item.deActivatedDate)
                              : new Date().toISOString() // Show current date when Denied Access is turned OFF
                          }
                        </td>
                        <td className="ins-switch">
                          <div className="info-content">
                            <span className="form-check form-switch">
                              {item?.isDataSync || item.dataSyncError !== "" ? (
                                <input
                                  type="checkbox"
                                  className="form-check-input cursor-pointer"
                                  role="switch"
                                  id={`flexSwitchCheckDefault-${item.id}`}
                                  checked={item.isActive} // Check if item is active
                                  onChange={() => {
                                    if (!item.isActive) {
                                      // If currently inactive, allow activation without confirmation
                                      handleConnectToPayer(item.payerID);
                                    } else {
                                      // If currently active, show confirmation modal before deactivation
                                      setPendingPayerUpdate({
                                        id: item.id,
                                        isActive: !item.isActive,
                                      });
                                      handleConfirmDeny(item);
                                    }
                                  }}
                                />
                              ) : (
                                <img
                                  src={SyncIocon}
                                  alt="Client Logo"
                                  className="h-26"
                                />
                              )}
                            </span>
                            <Tooltip anchorSelect=".dropdowninfo" />
                            <div
                              className="dropdowninfo"
                              data-tooltip-id="Warning"
                              data-tooltip-content={item?.dataSyncError}
                              data-tooltip-place="top"
                            >
                              {item?.dataSyncError && isAlreadyCheck && (
                                <i
                                  className="fh-icons"
                                  type="button"
                                  aria-expanded="false"
                                >
                                  fh_warning
                                </i>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </PerfectScrollbar>
            {totalCount > 0 && (
              <div className="d-flex justify-content-end">
                <nav
                  aria-label="Page navigation"
                  className="d-flex align-items-center"
                >
                  <span className="pagnination-info me-2">Page</span>
                  <ul className="pagination d-flex mb-0">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button className="page-link" onClick={handlePrevPage}>
                          <i className="fh_arrow_left_line"></i>
                        </button>
                      </li>
                    )}

                    <li className="page-item">
                      <button className="page-link current-page">
                        {currentPage}
                      </button>
                    </li>
                    {currentPage < totalPages && (
                      <li className="page-item">
                        <button
                          className="page-link me-0"
                          onClick={handleNextPage}
                        >
                          <i className="fh_arrow_right_line"></i>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Denial */}
      <Modal
        show={showDenyConfirmation}
        onHide={handleCancelDeny}
        centered
        className="custom-pop-up"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Confirm Access Denial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to deny access to your Previous Insurer Data{" "}
            <strong>{payerName}</strong>. The application will no longer be able
            to access patient data, including claims, encounters, and other
            related information. You can reverse this decision easily by
            re-enabling access later.
          </p>          
          <p style={{ marginBottom: "1rem", fontWeight: "bold" }}>
            Are you sure you want to deny access now?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDeny}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDenyConfirmed}>
            Yes, Deny Access
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={handleUnsubscribeModal}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a secure way to fetch your information from your previous
            insurance portal. You will be redirected to the portal, where you
            can access your information.
          </p>
          <p>
            If you encounter any issues, please reach out to us at&nbsp;
            <a href="mailto:support@aaneelconnect.com">
              support@aaneelconnect.com
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUnsubscribeModal}>
            Not now
          </Button>
          <Button variant="primary" onClick={handlePrevInsuranceRedirection}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast for Error Messages */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => {
            handleClose();
            setShowToast(false);
            setToastVisible(false);
          }}
          show={showToast}
          delay={5000}
          autohide
          className={
            isAlreadyCheck
              ? "bg-warning text-dark"
              : showErrorModel
              ? "bg-danger text-white"
              : isFromPayerCallback && !isSyncComplete
              ? "bg-warning text-dark"
              : isSyncComplete
              ? "bg-success text-white"
              : ""
          }
        >
          <Toast.Body className="fs-6">
            {isAlreadyCheck
              ? "Payer is already connected. Please choose another payer to continue."
              : showErrorModel
              ? "Please select a payer from the list to continue."
              : isFromPayerCallback && !isSyncComplete
              ? "We are fetching your data"
              : isFromPayerCallback && isSyncComplete
              ? "Data fetched sucessfully."
              : ""}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default PreviousInsurer;
