import React, { useState, useEffect, useCallback } from "react";
import { hl7Instance, dbInstance } from "../../api/axios";
import Modal from "react-bootstrap/Modal";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { useAuth } from "../../context/auth-context";
import clientImg from "../../assets/img/CMS_blue_button_1.svg";
import userImg from "../../assets/img/avatar-male.svg";
import userladyImg from "../../assets/img/avatar-female.svg";
import "./Aboutme.scss";
import { redirectToAuthorizationEndpoint } from "../../config/blueButtonOAuthConfig";
import {
  toTitleCase,
  formatDate,
  formatToMMDDYYYY,
  formatPhoneNumber,
} from "../../utils/Utils";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getBBPatientData, revolkBBAccesss } from "../../redux/slice/BlueButtonSlice";
import SyncIocon from "../../assets/img/sync.gif";

const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

const AboutMe = () => {
  const dispatch = useDispatch()
  const { setBlueButtonToken, setCoveragePlanCode, setPatientIDHL7 } = useAuth();
  const blueButtonData = useSelector((state) => state.blueButtonData?.blueButtonData)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [coverageData, setCoverageData] = useState(null);
  const [Emailfordisplay, setEmailfordisplay] = useState();
  const [bb_patientID, setbb_patientID] = useState();
  const navigate = useNavigate();
  const handleBlueButtonSignIn = () => {
    redirectToAuthorizationEndpoint();
  };
  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const isImpersonate = localStorage.getItem("isImpersonate") === "true";
  const [isBBActive, setIsBBActive] = useState(null);
  const getBBPatient = async () => {
    const ssoAccessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(ssoAccessToken.split(".")[1]));
    const userSub = tokenPayload.sub;

    const response = await dispatch(getBBPatientData(userSub));
    const bbActiveStatus = await response.payload?.bbIsActive;

    if (bbActiveStatus === "True") {
      setIsCheckboxChecked(true); // Ensure checkbox is checked
      // await fetchBluebuttonToken(response.payload.bB_RefreshToken);
    } else {
      setIsCheckboxChecked(false);
    }
  };

  useEffect(() => {
    getBBPatient();
  }, [dispatch]);

 
   let BB_PatientData={
     id:blueButtonData?.id,
     ssoUserId: blueButtonData?.ssoUserId,
     bbMemberID: null,
     bbIsActive:"No",
     bbRefreshToken:null,
     accessGrantExpiration: blueButtonData?.accessGrantExpiration,
     isDataSync: blueButtonData?.isDataSync,
   }
 
   const updateDatabase = async (New_refresh_token) => {
     if (!New_refresh_token) {
       return;
     }
     dispatch(revolkBBAccesss(BB_PatientData))
   };
   
   const updateDatabaseOnInvalidGrant = async () => {
     try {
   
 
    await dispatch(revolkBBAccesss(BB_PatientData))
     } catch (error) {}
   };
   
  
  const fetchBluebuttonToken = async (refreshToken) => {
    if (isFetchingToken || !refreshToken) return;
    setIsFetchingToken(true);

    const clientId = process.env.REACT_APP_BLUEBUTTON_OIDC_CLIENTID;
    const clientSecret = process.env.REACT_APP_BLUEBUTTON_OIDC_CLIENTSECRET;
    const tokenEndpoint = process.env.REACT_APP_BLUEBUTTON_TOKENENDPOINT;
    const credentials = window.btoa(`${clientId}:${clientSecret}`);
    const bodyPayload = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });

    axios
      .post(tokenEndpoint, bodyPayload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${credentials}`,
        },
      })
      .then(async (response) => {
        const responseData = response.data;
        await updateDatabase(blueButtonData?.bbRefreshToken);
        localStorage.removeItem("B_RefreshToken");
        localStorage.removeItem("B_patientId");
        localStorage.removeItem("BB_NewAccess_Token");
        localStorage.removeItem("local_B_AccessToken");
        const B_accestoken = responseData.access_token;
        sessionStorage.setItem("Session_B_AccessToken", B_accestoken);
        localStorage.setItem("local_B_AccessToken", B_accestoken);
        setBlueButtonToken(responseData.access_token);
      })
      .catch((error) => {
        console.error("Error fetching new access token:", error);
      })
      .finally(() => {
        setIsFetchingToken(false);
      });
  };

  const revokAccessBluebutton = async () => {
    if (isFetchingToken) return;
    setIsFetchingToken(true);
    try {
      const clientId = process.env.REACT_APP_BLUEBUTTON_OIDC_CLIENTID;
      const clientSecret = process.env.REACT_APP_BLUEBUTTON_OIDC_CLIENTSECRET;
      const tokenEndpoint = process.env.REACT_APP_BLUEBUTTON_RevockAccess;
      const credentials = window.btoa(`${clientId}:${clientSecret}`);
      const url = `${tokenEndpoint}${bb_patientID}/`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${credentials}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setBlueButtonToken(responseData.access_token);
    } catch (error) {
    } finally {
      setIsFetchingToken(false);
    }
  };

  const fetchPatientData = useCallback(async () => {
    if (isFetchingData) return;
    setIsFetchingData(true);

    try {
      let accessToken;
      let ImpersonateUserID = localStorage.getItem("ImpersonateUserID");
      let userSub = "";
      const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");
      if (ImpersonateUserID) {
        userSub = ImpersonateUserID;
        accessToken = PortalToken;
      } else {
        accessToken = await getAccessToken();
        const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
        userSub = tokenPayload.sub;
      }
      const patientIdResponse = await PreviousInsuranceApi.getBBMemberDetails(
        userSub
      );
      if (patientIdResponse.bB_IsActive === "True") {
        await fetchBluebuttonToken(patientIdResponse.bB_RefreshToken);
        //setIsCheckboxChecked(true);
      }
      setPatientIDHL7(patientIdResponse.memberID);
      setEmailfordisplay(patientIdResponse.email);
      setbb_patientID(patientIdResponse.bB_MemberID);
      const retrievedPatientId = patientIdResponse.memberID;

      const [patientResponse, coverageResponse] = await Promise.all([
        hl7Instance.get(`/Patient/${retrievedPatientId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
        hl7Instance.get(`/Coverage?beneficiary=${retrievedPatientId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      ]);
      setPatientData(patientResponse.data);

      if (patientData) {
        const fullName = `${patientData.name?.[0]?.given.map((name) => name).join(" ") +
          " " +
          patientData.name?.[0]?.family
          }`;

        localStorage.removeItem("Impersonate_MemberName");
        localStorage.setItem("Impersonate_MemberName", fullName);
      }
      const coverageResource = coverageResponse.data.entry[0].resource;
      setCoverageData({
        planName: coverageResource.type.text,
        planCode: coverageResource.type.coding[0]?.code,
        startDate: formatToMMDDYYYY(coverageResource.period.start),
        endDate: coverageResource.period.end
          ? formatToMMDDYYYY(coverageResource.period.end)
          : null,
        status: coverageResource.status,
      });
      setCoveragePlanCode(coverageResource.type.coding[0]?.code);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate("/login");
      }
    } finally {
      setIsFetchingData(false);
    }
  }, [
    isFetchingData,
    setPatientIDHL7,
    setCoveragePlanCode,
    navigate,
  ]);

  useEffect(() => {
    fetchPatientData();
  }, []);

  useEffect(() => { }, [isCheckboxChecked]);

  const handleUnsubscribe = async () => {
    await revokAccessBluebutton();
    await updateDatabaseOnInvalidGrant();
  };
  const getMedicareId = (data) => {
    let medicareId = "";
    if (data && data.identifier) {
      for (let id of data.identifier) {
        if (id.system === "http://hl7.org/fhir/sid/us-mbi") {
          medicareId = id.value;
          break;
        }
      }
    }
    return medicareId;
  };
  const getInsuranceId = (data) => {
    let Insuranceid = "";
    if (data && data.identifier) {
      for (let id of data.identifier) {
        if (id.system === "http://hl7.org/fhir/sid/insurance_card_no") {
          Insuranceid = id.value;
          break;
        }
      }
    }
    return Insuranceid;
  };
  function calculateAge(dob) {
    const dobDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - dobDate.getFullYear();
    const m = today.getMonth() - dobDate.getMonth();

    // If this year's birthday has not occurred yet, subtract one from age.
    if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
      age--;
    }
    return age;
  }

  const [show, setShow] = useState(false);
  const [unsubscribeShow, setUnsubscribeShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleUnsubscribeClose = () => setUnsubscribeShow(false);
  const handleShowModal = () => setShow(true);
  const handleUnsubscribeModal = () => setUnsubscribeShow(true);

  return (
    <>
      <div className="main-content">
        <div className="user-info-page">
          <div className="content-header">
            <h1 className="page-header">About Me</h1>
          </div>
          <div className="card info-card mb-4">
            <div className="card-body">
              {patientData ? (
                <>
                  <div className="row gap-2 align-items-xxl-center align-items-start">
                    <div className="col-12 col-lg-5 col-xl-5 col-xxl-4 mb-3 mb-lg-0">
                      <div className="d-flex align-items-center user-section">
                        <div className="user-img">
                          {patientData &&
                            patientData.gender.toLowerCase() === "male" ? (
                            <img src={userImg} alt="User" />
                          ) : (
                            <img src={userladyImg} alt="User" />
                          )}
                        </div>
                        <div className="info-block user-info-block">
                          <div className="member-header">
                            {toTitleCase(
                              patientData.name?.[0]?.given
                                .map((name) => name)
                                .join(" ") +
                              " " +
                              patientData.name?.[0]?.family
                            )}
                          </div>
                          <div className="gender-value">
                            {calculateAge(patientData?.birthDate)} years,&nbsp;
                            {patientData.gender}
                          </div>
                          <div className="dob">
                            DOB: {formatDate(patientData?.birthDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-7 col-xl-7 col-xxl row">
                      <div className="col-12 col-md col-xxl col-sm-6 col-lg-6 center-blocks custom-header-responsive gap-3 gap-lg-2 gap-xxl-4 mb-3 mb-md-0 mb-lg-0 mb-lg-3 mb-xxl-0">
                        <div className="d-flex col align-items-center block-margin">
                          <i className="fh_profile"></i>
                          <div className="info-block">
                            <div className="info-header">Insurance ID</div>
                            <div className="info-value">
                              {" "}
                              {getInsuranceId(patientData)}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex col align-items-center">
                          <i className="fh_assignment_fill"></i>
                          <div className="info-block">
                            <div className="info-header">Medicare ID</div>
                            <div className="info-value">
                              {getMedicareId(patientData)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md col-xxl col-sm-6 col-lg-6 center-blocks column gap-3 gap-lg-2 gap-xxl-4 mb-3 mb-md-0 mb-lg-0 mb-lg-3 mb-xxl-0">
                        <div className="d-flex col align-items-center block-margin">
                          <i className="fh_mobile"></i>
                          <div className="info-block">
                            <div className="info-header">Phone</div>
                            <div className="info-value">
                              {patientData.telecom[0].value ? formatPhoneNumber(patientData.telecom[0].value) : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex col align-items-center">
                          <i className="fh_email_fill"></i>
                          <div className="info-block">
                            <div className="info-header">Email</div>
                            <div className="info-value email">
                              {Emailfordisplay ? Emailfordisplay : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md col-xxl col-sm-12 col-xl-12 center-blocks gap-4">
                        <div className="d-flex col align-items-center align-items-sm-start">
                          <i className="fh_location_fill"></i>
                          <div className="info-block">
                            <div className="info-header">Address</div>
                            <div className="info-value">
                              {patientData.address[0].line
                                .map(toTitleCase)
                                .join(", ")}
                              , {toTitleCase(patientData.address[0].city)},{" "}
                              {patientData.address[0].state},{" "}
                              {patientData.address[0].postalCode.toUpperCase()},{" "}
                              {patientData.address[0].country}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p>Loading patient data...</p>
              )}
            </div>
          </div>
          <div className="row m-0 gx-4">
            <div className="col-sm-12 mb-4 mb-xl-0 col-xl-6 card p-0">
              <div className="card-body">
                <h2 className="card-top-header">Member Coverage</h2>
                {coverageData ? (
                  <>
                    <div className="row gap-3 gap-xxl-4">
                      <div className="sub-info">
                        <div className="d-flex align-items-center">
                          <i className="fh_umbrella"></i>
                          <div className="label-block">
                            <div className="label-header">Plan Name</div>
                            <div className="label-info">
                              {coverageData.planName}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex pe-3 col-auto align-items-center">
                        <i className="fh_profile"></i>
                        <div className="label-block">
                          <div className="label-header">Plan Code</div>
                          <div className="label-info">
                            {coverageData.planCode}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex pe-3 col-auto align-items-center">
                        <i className="fh_calendar_today_fill"></i>
                        <div className="label-block">
                          <div className="label-header">Start Date</div>
                          <div className="label-info">
                            {coverageData.startDate}
                          </div>
                        </div>
                      </div>
                      {coverageData.endDate && (
                        <div className="d-flex pe-3 col-auto align-items-center">
                          <i className="fh_calendar_today_fill"></i>
                          <div className="label-block">
                            <div className="label-header">End Date</div>
                            <div className="label-info">
                              {coverageData.endDate}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-flex pe-3 col-auto align-items-center">
                        <i className="fh_check_decagram"></i>
                        <div className="label-block">
                          <div className="label-header">Status</div>
                          <div className="label-info">
                            {toTitleCase(coverageData.status)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <p>Loading coverage data...</p>
                )}
              </div>
            </div>
            <div className="col-sm-12 mb-4 mb-xl-0 col-xl-6 ps-xl-4 pe-xl-0 p-0">
              <div className="card cms-card">
                <div className="card-body member-card-body">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-sm-7">
                      <div className="mem-info column">
                        <div className="member-info">
                          Do you want to see all old claims history?
                        </div>
                        <div className="member-name">
                          In order to view old claim history through the member
                          portal, Please{" "}
                          <span className="mem-mini-info">
                            enable Connect to Medicare button.
                          </span>
                        </div>
                        <div className="member-img-responsive">
                          <img src={clientImg} alt="about me"></img>
                        </div>
                        <div className="cms-switch">
                          <span>Import your Medicare claims</span>
                          <div className="icons-info">
                            <div className="form-check form-switch">
                     
                              {blueButtonData?.isDataSync ||
                              blueButtonData?.dataSyncError !== "" ? (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={isCheckboxChecked ? true : false}
                                  disabled={isImpersonate}
                                  onChange={(e) => {
                                    setIsCheckboxChecked(e.target.checked);
                                    if (e.target.checked) {
                                      handleShowModal();
                                    } else {
                                      handleUnsubscribeModal();
                                    }
                                  }}
                                />
                              ) : (
                                <img
                                  src={SyncIocon}
                                  alt="Client Logo"
                                  className="h-26"
                                />
                              )}
                            </div>
                            <Tooltip anchorSelect=".dropdowninfo" />
                            {blueButtonData?.dataSyncError && (
                              <div
                                className="dropdowninfo"
                                data-tooltip-id="Warning"
                                data-tooltip-content={
                                  blueButtonData?.dataSyncError &&
                                  blueButtonData?.dataSyncError
                                }
                                data-tooltip-place="top"
                              >
                                <i
                                  className="fh-icons warn-icon"
                                  type="button"
                                  aria-expanded="false"
                                >
                                  fh_warning
                                </i>
                              </div>
                            )}
 
                            {/* <i className="fh-icons  warn-icon">auto_renew</i> */}
                          </div>
                        </div>
                        {blueButtonData?.lastSyncDate && (
                          <div className="reported-info">
                            Last Sync Date : {blueButtonData?.lastSyncDate}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-5">
                      <span className="member-img">
                        <img src={clientImg} alt="about me"></img>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This is completely secured way to fetch your claim(s) information from
          CMS Medicare Portal. This page will redirect you to CMS Medicare
          Portal to fetch your claim(s) information. There you need to enter
          your Medicare Portal's credential, if you do not have, you may create
          the same from there.
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-border-primary me-2"
            onClick={() => {
              setIsCheckboxChecked(false);
              handleClose();
            }}
          >
            Not Now
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleBlueButtonSignIn}
          >
            Next
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={unsubscribeShow}
        onHide={handleUnsubscribeClose}
        centered
        className="custom-pop-up about-me-popup"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to unsubscribe from CMS data?
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-border-primary me-2"
            onClick={() => {
              setIsCheckboxChecked(true);
              updateDatabase()
              handleUnsubscribeClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleUnsubscribe();
              setIsCheckboxChecked(false);
              handleUnsubscribeClose();
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AboutMe;
