import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { hl7Instance, blueButtonData } from "../../api/axios";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { useAuth } from "../../context/auth-context";
import "./claims.scss";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/custom-loader/CustomLoader";
import {
  // calculateAge,
  toTitleCase,
  formatDate,
  formatCurrency,
  formatDateToDDMMYY,
} from "../../utils/Utils";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    // console.error("Error fetching access token:", error);
    throw error;
  }
}

function extractAmounts(adjudication) {
  let amounts = {
    billedAmount: null,
    allowedAmount: null,
    paidAmount: null,
  };

  adjudication.forEach((item) => {
    const display = item.category?.coding[0]?.display;
    switch (display) {
      case "Allowed Amount":
        amounts.allowedAmount = item.amount.value;
        break;
      case "Billed Amount":
        amounts.billedAmount = item.amount.value;
        break;
      case "Paid Amount":
        amounts.paidAmount = item.amount.value;
        break;
      default:
        break;
    }
  });

  return amounts;
}

const ClaimDetail = () => {
  const navigate = useNavigate();
  const { claimId, source } = useParams();
  const [eobData, setEobData] = useState(null);
  console.log("data from the eobData is================>",eobData)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const { blueButtonToken } = useAuth();

  const navigateBack = () => {
    // Retrieve the last source from local storage
    // const lastSource = localStorage.getItem("lastSource");
    // Navigate back to the claim search page with the last source as a state
    //navigate(-1, { state: { source: lastSource } });
    navigate("/claim", { state: { fromDetail: true, source: source } });
    // navigate("/claim", { state: { fromBlueButton: true } });
  };

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        if (source) {
          const token = await getAccessToken();
          setAccessToken(token);
        } 
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchAccessToken();
  }, [source, blueButtonToken]);

  useEffect(() => {
    const fetchEobData = async () => {
      try {
        if (!accessToken) {
          throw new Error("Access token not yet set.");
        }

        let responseData;
        const headers = { Authorization: `Bearer ${accessToken}` };

        if (source) {
          const response = await hl7Instance.get(
            `/ExplanationOfBenefit/${claimId}`,
            { headers }
          );
          responseData = response.data;
        } else {
          throw new Error("Invalid source provided.");
        }

        if (responseData) {
          setEobData(responseData);
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (accessToken) {
      fetchEobData();
    }
  }, [claimId, source, accessToken]);

  const extractAdjudicationAmounts = (adjudication) => {
    let amounts = {
      submittedAmount: "N/A",
      revenueCenterRateAmount: "N/A",
      revenueCenterTotalChargeAmount: "N/A",
      coinsuranceAmount: "N/A",
      paidByPatientAmount: "N/A",
    };

    adjudication.forEach((item) => {
      if (item.amount && item.amount.value != null) {
        const formattedAmount = formatCurrency(item.amount.value); // Use the imported function

        if (
          item.category?.coding?.some((coding) => coding.code === "submitted")
        ) {
          amounts.submittedAmount = formattedAmount;
        }
        if (
          item.category?.coding?.some(
            (coding) =>
              coding.code ===
              "https://bluebutton.cms.gov/resources/variables/rev_cntr_rate_amt"
          )
        ) {
          amounts.revenueCenterRateAmount = formattedAmount;
        }
        if (
          item.category?.coding?.some(
            (coding) =>
              coding.code ===
              "https://bluebutton.cms.gov/resources/variables/rev_cntr_tot_chrg_amt"
          )
        ) {
          amounts.revenueCenterTotalChargeAmount = formattedAmount;
        }
        if (
          item.category?.coding?.some((coding) => coding.code === "coinsurance")
        ) {
          amounts.coinsuranceAmount = formattedAmount;
        }
        if (
          item.category?.coding?.some(
            (coding) => coding.code === "paidbypatient"
          )
        ) {
          amounts.paidByPatientAmount = formattedAmount;
        }
      }
    });

    return amounts;
  };

  const RenderHealthPlanContent = () => (
    <>
      <div className="card mb-4">
        <div className="card-body pb-0">
          <div className="card-content">
            <div className="content-subheader">
              <h2 className="page-subheader">Claim Details</h2>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_profile"></i>
                  <div>
                    <label>Member ID</label>
                    <span>{eobData?.patient?.reference.split("/")[1]}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_doctor_info"></i>
                  <div>
                    <label>Provider Name</label>
                    <span>
                      {toTitleCase(eobData?.provider?.display || "N/A")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_doctor"></i>
                  <div>
                    <label>Attending Physician</label>
                    <span>
                      {toTitleCase(
                        eobData?.careTeam?.find(
                          (team) => team.role?.coding[0]?.code === "primary"
                        )?.provider?.display ||
                          eobData?.provider?.display ||
                          "N/A"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_task_2_fill"></i>
                  <div>
                    <label>Claim Number</label>
                    <span>{eobData?.id}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_event_fill"></i>
                  <div>
                    <label>Date of Service</label>
                    <span>{formatDateToDDMMYY(eobData?.billablePeriod?.start)}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_task_progress"></i>
                  <div>
                    <label>Claim Status</label>
                    <span>
                      {
                        eobData?.extension?.find(
                          (ext) =>
                            ext.url ===
                            "https://terminology.hl7.org/resources/codesystem/eob-status"
                        )?.valueCoding?.display
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_dollar_circle_fill"></i>
                  <div>
                    <label>Total Billed Amount</label>
                    <span>
                      {" "}
                      {formatCurrency(
                        eobData?.extension?.find(
                          (ext) =>
                            ext.url ===
                            "https://terminology.hl7.org/resources/codesystem/eob-billed-amount"
                        )?.valueMoney?.value
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4'>
                <div className='label-info-main d-flex align-items-center'>
                  <i className='fh_money'></i>
                  <div>
                    <label>Allowed Amount</label>
                    <span>N/A</span>
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_dollar_circle_fill"></i>
                  <div>
                    <label>Paid Amount</label>
                    <span>
                      {formatCurrency(eobData?.payment?.amount?.value)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Diagnosis Codes Table */}
      {eobData?.diagnosis?.length > 0 && (
        <div className="card mb-4">
          <div className="card-body">
            {eobData?.diagnosis?.length > 0 && (
              <>
                <div className="card-content">
                  <div className="content-subheader">
                    <h2 className="page-subheader">Diagnosis Codes</h2>
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eobData.diagnosis.map((diag, index) => (
                          <tr key={index}>
                            <td className="fw-bold">
                              {diag.diagnosisCodeableConcept?.coding[0].code}
                            </td>
                            <td>
                              {diag.diagnosisCodeableConcept?.coding[0].display}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className="card mb-4">
        <div className="card-body">
          {eobData?.item?.length > 0 && (
            <>
              <div className="card-content">
                <div className="content-subheader">
                  <h2 className="page-subheader">Service</h2>
                </div>
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Service Description</th>
                        <th>Date of Service</th>
                        <th>Billed Amount</th>
                        <th>Allowed Amount</th>
                        <th>Paid Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eobData.item.map((item, index) => {
                        const amounts = extractAmounts(item.adjudication);
                        return (
                          <tr key={index}>
                            <td>
                              {toTitleCase(
                                item.productOrService.coding[0].display
                              )}
                            </td>
                            <td>{formatDateToDDMMYY(item.servicedPeriod?.start)}</td>
                            <td>{formatCurrency(amounts.billedAmount)}</td>
                            <td>{formatCurrency(amounts.allowedAmount)}</td>
                            <td className="fw-bold">
                              {formatCurrency(amounts.paidAmount)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
  const RenderCMSContent = () => (
    <>
      <div className="card mb-4">
        <div className="card-body">
          <div className="card-content">
            <div className="content-subheader">
              <h2 className="page-subheader">Claim Details</h2>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_profile"></i>
                  <div>
                    <label>Member ID</label>
                    <span>{eobData?.patient?.reference.split("/")[1]}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_doctor_info"></i>
                  <div>
                    <label>Provider Name</label>
                    <span>
                      {toTitleCase(eobData?.contained?.[0]?.name || "N/A")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_doctor"></i>
                  <div>
                    <label>Attending Physician</label>
                    <span>
                      {eobData?.careTeam?.find(
                        (team) => team.role?.coding[0]?.code === "primary"
                      )?.provider?.display ||
                        eobData?.provider?.display ||
                        "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_task_2_fill"></i>
                  <div>
                    <label>Claim Number</label>
                    <span>
                      {eobData?.identifier?.find(
                        (ident) =>
                          ident.system ===
                          "https://bluebutton.cms.gov/resources/variables/clm_id"
                      )?.value || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_event_fill"></i>
                  <div>
                    <label>Date of Service</label>
                    <span>
                      {formatDate(eobData?.billablePeriod?.start) || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_task_progress"></i>
                  <div>
                    <label>Claim Status</label>
                    <span>{toTitleCase(eobData?.status || "N/A")}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_dollar_circle_fill"></i>
                  <div>
                    <label>Total Billed Amount</label>
                    <span>{formatCurrency(eobData?.total?.value)}</span>
                  </div>
                </div>
              </div>
              {/* <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4'>
                <div className='label-info-main d-flex align-items-center'>
                  <i className='fh_money'></i>
                  <div>
                    <label>Allowed Amount</label>
                    <span></span>
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                <div className="label-info-main d-flex align-items-center">
                  <i className="fh_dollar_circle_fill"></i>
                  <div>
                    <label>Paid Amount</label>
                    <span>
                      {formatCurrency(eobData?.payment?.amount?.value)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Diagnosis Codes Table */}
      {eobData?.diagnosis?.length > 0 && (
        <div className="card mb-4">
          <div className="card-body">
            {eobData?.diagnosis?.length > 0 && (
              <>
                <div className="card-content">
                  <div className="content-subheader">
                    <h2 className="page-subheader">Diagnosis Codes</h2>
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eobData.diagnosis.map((diag, index) => (
                          <tr key={index}>
                            <td>
                              {diag.diagnosisCodeableConcept?.coding[0].code}
                            </td>
                            <td>
                              {toTitleCase(
                                diag.diagnosisCodeableConcept?.coding?.[0]
                                  ?.display ?? ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className="card">
        <div className="card-body">
          {eobData?.item?.length > 0 && (
            <>
              <div className="card-content">
                <div className="content-subheader">
                  <h2 className="page-subheader">Service</h2>
                </div>
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Service Description</th>
                        {/* <th>Date of Service</th> */}
                        <th>Submitted</th>
                        <th>Revenue Center Rate</th>
                        <th>Revenue Center Total Charge</th>
                        <th>Co-insurance</th>
                        <th>Paid by Patient</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eobData.item.map((item, index) => {
                        const amounts = extractAdjudicationAmounts(
                          item.adjudication
                        );
                        return (
                          <tr key={index}>
                            <td>
                              {" "}
                              {item.productOrService.coding[0].display ||
                                item.productOrService.coding[0].code}
                            </td>
                            {/* <td>{item.servicedDate}</td> */}
                            <td>{amounts.submittedAmount}</td>
                            <td>{amounts.revenueCenterRateAmount}</td>
                            <td>{amounts.revenueCenterTotalChargeAmount}</td>
                            <td>{amounts.coinsuranceAmount}</td>
                            <td>{amounts.paidByPatientAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className="main-content">
      <div className="content-header">
        <button
          type="button"
          className="btn btn-border-primary h-48 d-flex align-items-center"
          onClick={navigateBack}
        >
          <i className="fh_arrow_left icon-mr"></i>
          <span>Back to claim list</span>
        </button>
      </div>

      {source === "CMS" ? (
         <RenderCMSContent />
      
      ) : (
        <RenderHealthPlanContent />
      )}

      {loading && <CustomLoader />}
      {error && <p>Error: {error.message}</p>}
    </div>
  );
};

export default ClaimDetail;
